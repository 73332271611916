import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import '@/styles/index.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import echarts from 'echarts'
import md5 from 'js-md5'
import isWeixin from './utils/weixn'
Vue.prototype.weixin = isWeixin
Vue.prototype.$md5 = md5
Vue.prototype.$echarts = echarts

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
Vue.use(Vant)


// Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
