import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import('@/views/home/index')
  },
  {
    path: '/reviewing',
    name: 'Reviewing',
    component: () =>
      import('@/views/reviewing/index')
  },
  {
    path: '/answerCard',
    name: 'AnswerCard',
    component: () =>
      import('@/views/answerCard/index')
  },
  {
    path: '/testResult',
    name: 'TestResult',
    component: () =>
      import('@/views/testResult/index')
  },
  {
    path: '/parsingItem',
    name: 'ParsingItem',
    component: () =>
      import('@/views/reviewing/parsingItem')
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () =>
      import('@/views/ranking/index')
  },
  {
    path: '/hello',
    name: 'Hello',
    component: () =>
      import('@/views/helloword/index')
  }
]
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
// 解决vue中的NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
