<template>
  <div id="app">
    <router-view />
    <!-- <router-view v-if="isRouterAlive" /> -->
  </div>
</template>
<script>
export default {
  // components: {
  //   'page-header': pageHeader
  // },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      toPath: ''
    }
  },
  created() {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if(this.weixin){
      return false
    }
    if (isiOS) {
      window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
    } else {
      window.android.vue_Fullscreen('1')
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style>
.van-checkbox--disabled{
  color: #323233 !important;
  background: transparent !important;
}
.van-checkbox__label--disabled{
  color: #323233 !important;
}
/* .van-checkbox--disabled .van-icon{
  color: #fff !important;
  border-color: #ee2e2e !important;
  background: #ee2e2e !important;
} */
</style>
<style lang="scss">
html,body{
    height:100%;
    width: 100%;
}
body{
  margin: 0;
}
*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  // background: #fff;
  margin: 0;
}
input,button{
  border: none;
  outline: none;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
ol, ul, dl{
  margin: 0;
  padding: 0;
}
a:focus,
a:active {
  outline: none;
}
ul li{
  list-style: none;
}
div:focus {
  outline: none;
}
a{
  text-decoration: none;
}
a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
ol, ul, dl{
  margin: 0;
  padding: 0;
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
</style>
