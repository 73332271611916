import wx from "weixin-js-sdk"
var ua = window.navigator.userAgent.toLowerCase();
var isWeixin = false
// let that = this;
if (ua.match(/MicroMessenger/i) == "micromessenger") {
  //微信环境下
  isWeixin = true
  wx.miniProgram.getEnv(function (res) {
    if (res.miniprogram) {
      // 小程序环境下逻辑
      console.log("小程序环境下")
      isWeixin = true
    } else {
      //非小程序环境下逻辑
      console.log("非小程序环境下")
      isWeixin = false
    }
  });
}

export default isWeixin